<!--
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full bg-gray-50">
    <body class="h-full">
    ```
  -->
  <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <!-- <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="Your Company" /> -->
      <h2 class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">曼馱有限公司</h2>
      <h2 class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">報告系統</h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form class="space-y-6" @submit.prevent="token" action="/" method="POST">
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">Email address</label>
            <div class="mt-1">
              <input v-model="username" id="email" name="email" type="email" autocomplete="email" required="" class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" />
            </div>
          </div>

          <div>
            <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
            <div class="mt-1">
              <input v-model="password"  id="password" name="password" type="password" autocomplete="current-password" required="" class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" />
            </div>
          </div>

          <!-- <div>
            <button @click="token()" type="button" class="sign_in_button">Sign in</button>
          </div> -->
          <div>
            <button @click="getToken()" type="button" class="sign_in_button">登入</button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- <div>
    <button @click="getlist()" type="button" class="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Sign in</button>
  </div> -->
</template>

<script setup>
import { ref } from 'vue';
import router from '@/router';
// import { Requests } from '@/helpers/utilities'
// import { API } from '@/helpers/urls'
import { oauth } from '@/providers'
import { setTokenInfo } from '@/helpers/useToken'

// const url = 'data.json';
// const url = 'https://dashboard.foodaiot.com/';
let username = ref('')
let password = ref('')

if(localStorage.token != null){
  router.push('/report')
}
// const api = reactive({data:'',})
// if(process.env.VUE_APP_TBPE_USERNAME != 'undefine'){
//   username = process.env.VUE_APP_TBPE_USERNAME
//   console.log(username.value)
// }

// if(process.env.VUE_APP_TBPE_PASSWORD != 'undefine'){
//   password = process.env.VUE_APP_TBPE_PASSWORD
// }
    
// const token = async (url=API.GET_TOKEN) => {
//   const res = await Requests.GetToken(url, username.value, password.value).catch((error)=>console.log(error));
//   localStorage.token = ''
//   localStorage.refreshtoken = ''
//   localStorage.token = 'Bearer ' + res.data.token
//   localStorage.refreshtoken = res.data.refreshToken
//   localStorage.token_exp = VueJwtDecode.decode(res.data.token).exp
//   localStorage.refreshtoken_exp = VueJwtDecode.decode(res.data.refreshToken).exp
//   router.push('/report')
// }

async function getToken() {
  try {
    // Call the oauth.login function with email and password
    const response = await oauth.login({ email: username.value, password: password.value });

    // On successful login:
    // Store the token and refresh token in the application state
    setTokenInfo(response.data);
    // setRefreshToken(response.data.refreshToken);

    // Store the token expiration timestamps in localStorage
    

    // Redirect the user to the '/report' route using the Vue Router
    router.push('/report');
  } catch (error) {
    // On login error:
    console.error(error);
    // show an alert to the user
    alert("登入失敗，請檢查帳號密碼是否正確");
  }
}

// function getToken(){
//   oauth.login({email: username.value, password:password.value}).then(response => {
//     // console.log(response.data)
//     setToken('Bearer ' + response.data.token)
//     setRefreshToken(response.data.refreshToken)
//     localStorage.token_exp = VueJwtDecode.decode(response.data.token).exp
//     localStorage.refreshtoken_exp = VueJwtDecode.decode(response.data.refreshToken).exp
//     router.push('/report')
//     // window.location.href = "/"
//   }).catch(error => {
//       console.error(error)
//       // alert("登入失敗, 請檢查帳號密碼是否正確")
//   })  
// }
</script>

<style lang="postcss" scoped>

.sign_in_button{
  @apply flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2
}


</style>