import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import LogoutView from '../views/LogoutView.vue'

const routes = [
  { path: '/', redirect: () => { return '/login' }},
  { path: '/login', name: 'login', component: LoginView },
  { path: '/logout', name: 'logout', component: LogoutView, meta: { requiresAuth: true }}, // 需驗證 
  { path: '/report', name: 'report', component: () => import('../views/ReportView.vue'), meta: { requiresAuth: true }}, // 需驗證  
  { path: '/bd-report', name: 'bd-report', component: () => import('../views/BDReportView.vue'), meta: { requiresAuth: true }}, // 需驗證  
  { path: '/presteam-report', name: 'presteam-report', component: () => import('../views/PreSteamReportView.vue'), meta: { requiresAuth: true }}, // 需驗證  
  { path: '/presteam-report-monthly', name: 'presteam-report-monthly', component: () => import('../views/PreSteamReportMonthlyView.vue'), meta: { requiresAuth: true }}, // 需驗證  
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // 如果 router 轉跳的頁面需要驗證 requiresAuth: true
  // console.log('to=', to.fullPath, '| from=', from.fullPath);
  if (to.matched.some(record => {
    // console.log(record.name, record.meta.requiresAuth);
    return record.meta.requiresAuth;
  })) {
    // 如果沒有 token 
    // console.log('token?', localStorage.token);
    if (localStorage.token === '') {
      // 轉跳到 login page
      next({ path: '/login' });
    } else {
      next(); // 往下繼續執行
    }
  } else {
    next(); // 往下繼續執行
  }
});

export default router
