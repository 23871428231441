import { axiosInstance } from "./helpers/axios.js"
// import { API } from "./helpers/urls.js"

export const oauth = {
    login: (credentials) => {
        const data = {
            username: credentials.email,
            password: credentials.password
        }
        // console.log(data)
        return axiosInstance.post("api/auth/login", data)
    },
    refreshNewToken: (t) => {
        const data = {
            refreshToken: t,
        }
        // console.log(data)
        return axiosInstance.post("api/auth/token", data)
    },
}

export const device = {
    // get telemetry period with params 
    getTelemetryPeriod: (id, params) => {
        return axiosInstance.get(`api/plugins/telemetry/DEVICE/${id}/values/timeseries`, { params: params })
    },

    getAttributes: (id, scope, keys) => {
        return axiosInstance.get(`api/plugins/telemetry/DEVICE/${id}/values/attributes/${scope}?keys=${keys}`)
    }
}

