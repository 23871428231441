<template>
  Logout
</template>

<script setup>
import { onMounted } from 'vue';
import router from '@/router';
import { deleteToken } from '@/helpers/useToken'; 
  
// delete token then redirect to login page
onMounted(() => {
  deleteToken();
  router.push('/login');
});

</script>