import axios from "axios"
import { API } from "./urls"
import { token } from "@/helpers/useToken"

export const axiosInstance = axios.create({baseURL: API.BASE_URL})

axiosInstance.interceptors.request.use(async (config) => {
    const accessToken = token.value
    if (accessToken) config.headers["X-Authorization"] = `${accessToken}`
    return config
})