import { ref } from 'vue';
import VueJwtDecode from 'vue-jwt-decode';

export const token = ref(localStorage.getItem('token') || null);
const refreshToken = ref(localStorage.getItem('refreshtoken') || null);

export const setTokenInfo = (data) => {
    token.value = 'Bearer ' +  data.token;
    refreshToken.value = data.refreshToken;
    localStorage.setItem('token', token.value);
    localStorage.setItem('refreshtoken', refreshToken.value);
    localStorage.setItem('token_exp', VueJwtDecode.decode(data.token).exp);
    localStorage.setItem('refreshtoken_exp', VueJwtDecode.decode(data.refreshToken).exp);
};

export const deleteToken = () => {
    token.value = null;
    refreshToken.value = null;
    localStorage.removeItem('token');
    localStorage.removeItem('refreshtoken');
};

export const checkValidToken = () => {
    const token_exp = localStorage.getItem('token_exp');
    const refreshtoken_exp = localStorage.getItem('refreshtoken_exp');
    const now = Math.floor(Date.now() / 1000);
    if (token_exp && refreshtoken_exp) {
        if (now > token_exp && now < refreshtoken_exp) {
            return true;
        }
    }
    return false;
}

