<template>
  <nav>
    <router-link to="/login">Login</router-link>  |
    <router-link to="/report">STM蒸煮區</router-link>   | 
    <router-link to="/bd-report">BD 攪拌間</router-link>  | 
    <router-link to="/presteam-report">預發室</router-link>  | 
    <router-link to="/presteam-report-monthly">預發室月報表</router-link>  | 
    <router-link to="/logout">Logout</router-link>
  </nav>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #3949AB;
}


</style>
